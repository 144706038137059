import { IGroupingClickableCardsFields } from './../../../../types/ContentfulTypes.d';
import styled, { css } from 'styled-components';
import { breakpointUp, spacer, variable } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import Grid from '../../../Grid/Grid';
import ContentfulTileCategory from '../ContentfulTileCategory/ContentfulTileCategory';
import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';

interface StyledGroupingTilesProps {
  count?: number;
}

export const StyledGroupingTiles = styled(Grid)<StyledGroupingTilesProps>`
  gap: ${spacer(100)};
  grid-template-columns: 1fr;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css<StyledGroupingTilesProps>`
      grid-template-columns: ${({ count = 0 }) => count > 1 && `1fr 1fr`};
    `
  )}
`;

export const StyledContentfulTileCategory = styled(ContentfulTileCategory)`
  transition: ${variable('transition')};
  padding: ${spacer(25)} 0px;

  &:hover {
    transform: scale3d(1.01, 1.01, 1.01);
  }
`;

interface StyledSliderEmblaProps {
  stylingType: IGroupingClickableCardsFields['stylingType'];
}

export const StyledSliderEmbla = styled(SliderEmbla)<StyledSliderEmblaProps>`
  ${({ stylingType }) =>
    stylingType === 'My Account' &&
    css`
      display: none;

      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          display: unset;
        `
      )};
    `}
`;

export const StyledMyAccountMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacer(50)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: none;
    `
  )};
`;
