import Box from 'components/Box/Box';
import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import { BREAKPOINTS } from 'constants/breakpoints';
import { ARROW_POSITIONS } from 'constants/generic';
import { EmblaOptionsType } from 'embla-carousel';
import breakpointValues from 'theme/variables/breakpoints';
import { IGroupingClickableCards } from 'types/ContentfulTypes';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';
import ContentfulTileLifeStyle from '../ContentfulTileLifeStyle/ContentfulTileLifeStyle';
import { TileLifeStyleVariant } from '../types';
import { StyledMyAccountMobileWrapper, StyledSliderEmbla } from './ContentfulGroupingClickableCards.styled';

const variantColors = ['primary-400', 'secondary-300', 'primary-400', 'black'];

interface ContentfulGroupingClickableCardsProps {
  content: IGroupingClickableCards;
  showLinkLabel?: boolean;
  tileLifeStyleVariant?: TileLifeStyleVariant;
}

const ContentfulGroupingClickableCards = ({
  content,
  showLinkLabel,
  tileLifeStyleVariant,
}: ContentfulGroupingClickableCardsProps) => {
  if (!content?.fields) {
    return null;
  }

  const { items, title } = content.fields;

  const options: EmblaOptionsType = {
    breakpoints: {
      [`(min-width: ${breakpointValues.md}px)`]: { slidesToScroll: 3 },
      [`(min-width: ${breakpointValues.lg}px)`]: { slidesToScroll: 4 },
      [`(min-width: ${breakpointValues.xl}px)`]: { slidesToScroll: 5 },
    },
  };

  const showDots = { [BREAKPOINTS.XS]: false };
  const showArrows = { [BREAKPOINTS.MD]: true };
  const slidesToShow: SlidesObject = {
    [BREAKPOINTS.XS]: 1.5,
    [BREAKPOINTS.MD]: 3,
    [BREAKPOINTS.LG]: 4,
    [BREAKPOINTS.XL]: 5,
  };

  return (
    <ContentfulGroupingWrapper>
      {title && <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>}

      {content.fields?.stylingType === 'My Account' && (
        <StyledMyAccountMobileWrapper>
          {items.map((item, index) => (
            <ContentfulTileLifeStyle
              key={index}
              // @ts-ignore
              background={variantColors[(index + 4) % 4]}
              content={item}
              showLinkLabel={showLinkLabel}
              stylingType={content.fields?.stylingType}
            />
          ))}
        </StyledMyAccountMobileWrapper>
      )}

      <StyledSliderEmbla
        showDots={showDots}
        showArrows={showArrows}
        options={options}
        arrowPosition={ARROW_POSITIONS.CENTER}
        slidesToShow={slidesToShow}
        stylingType={content.fields?.stylingType}
      >
        {items.map((item, index) => (
          <Box key={item.sys.id} fullHeight padding={25}>
            <ContentfulTileLifeStyle
              // @ts-ignore
              background={variantColors[(index + 4) % 4]}
              content={item}
              showLinkLabel={showLinkLabel}
              tileLifeStyleVariant={tileLifeStyleVariant}
              stylingType={content.fields?.stylingType}
            />
          </Box>
        ))}
      </StyledSliderEmbla>
    </ContentfulGroupingWrapper>
  );
};

export default ContentfulGroupingClickableCards;
